<template>
    <div class="container-fluid">
        <div class="row">
            <div v-for="s in sections" class="col-12 col-sm-6 col-md-3 col-xl-2 mb-3" :key="s.tag">
                <div class="card h-100">
                    <img class="card-img-top img-fluid mt-3" :src="'https://infotechnic.tecnoclub.org/images/' + s.tag +'.jpg'" alt="Card image cap">
                    <div class="card-body p-2">
                        <h5 class="card-title">{{ s.title }}</h5>
                        <p class="card-text small">{{ s.text }}</p>
                        <button @click.prevent="goTo(s.tag + '.pdf')" class="btn btn-primary">Ver manual</button>
                    </div>
                </div>
            </div>

        </div>
        <!-- End row -->
    </div>
</template>

<script>

    import settings from "../settings";
    let PARTNER = settings.PARTNER;

    let sections = [
        {title: 'DM9030', tag: 'dm9030', text: 'Polímetro'},
        {title: 'FASANGAS', tag: 'fasangas', text: 'Polímetro'},
        // {title: 'UPO2102CS', tag: 'osciloscopio', text: 'Osciloscopio de 2 canales'},
        // {title: 'Autel Maxi IM608', tag: 'autel_608', text: 'Diagnosis y codificación de llaves'},
    ]

    if (PARTNER == 'cga') { sections.push({title: 'Socio400', tag: 'socio400', text: 'Máquina de diagnosis'}) }
    if (PARTNER == 'atehi') {
        sections.push({title: 'Analizador de Gases', tag: 'analizador', text: 'Analizador de 5 gases'})
        sections.push({title: 'Opacimetro', tag: 'opacimetro', text: 'Opacimetro para vehiculos diesel'})
        sections.push({title: 'DS903 v2', tag: 'DS903v2', text: 'Sangrador de frenos'})
        sections.push({title: 'Syncro Express', tag: 'syncro_express', text: 'Descarbonizadora limpiadora'})
        sections.push({title: 'Syncro Plus', tag: 'syncro_plus', text: 'Descarbonizadora limpiadora'})
        sections.push({title: 'Foxwell GT80 Mini', tag: 'foxwell_gt80', text: 'Máquina de diagnosis'})
        sections.push({title: 'Foxwell GT90', tag: 'foxwell_gt90', text: 'Máquina de diagnosis'})
    }
    if (PARTNER == 'create') {
      sections.push({title: 'TOPDON', tag: 'topdon', text: 'Máquina de diagnosis'})
      sections.push({title: 'AGS688', tag: 'ags688', text: 'Analizador de gases'})
    }



    export default {
        name: "lessons-view",
        props: ['logged'],
        data() {
            return {
                sections: sections
            }
        },
        methods: {
            goTo(url) {
                window.open('https://'+PARTNER+'.tecnoclub.org/docs/equipamiento/'+url);
            }
        },
        created() {
            if(this.logged == 0){
                this.$router.push('/')
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../theme';
    @import '../../node_modules/bootstrap/scss/bootstrap.scss';
</style>
