<template>
    <div class="container-fluid">
        <div class="row" v-if="dir==''">
            <div v-for="s in sections" class="col-12 col-sm-6 col-md-3 col-xl-2 mb-3" :key="s.tag">
                <div class="card">
                    <img class="card-img-top" :src="'https://infotechnic.tecnoclub.org/images/' + s.tag +'.jpg'" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">{{ s.title }}</h5>
                        <p class="card-text small">{{ s.text }}</p>
                        <a href="#" class="btn btn-primary" @click="getDir(s.tag)">Ver procesos</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- End row -->
        <div class="row" v-else>
            <div class="container">
                <button class="btn btn-dark mb-3" @click="goBack"><i class="fas fa-arrow-alt-left"></i> Atrás</button>
                <table class="table table-dark table-striped table-hover">
                    <tbody style="cursor:pointer">
                    <tr @click="goTo(dir+'/'+f+'.pdf')" v-for="f in files" :key="f"><td>{{ f }}</td></tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- End row -->
    </div>
</template>

<script>
    import axios from 'axios';
    import settings from "../settings";
    let API_URL = settings.API_URL;
    let PARTNER = settings.PARTNER;
    export default {
        name: "process-view",
        props: ['logged'],
        data() {
            return {
                sections: [
                    {title: 'Sensores', tag: 'sensores', text: 'Captadores, sensores, potenciómetros...'},
                    {title: 'Actuadores', tag: 'actuadores', text: 'Electroválvulas, servomotores, reguladores, inyectores...'}
                ],
                dir: '',
                files: []
            }
        },
        methods: {
            getDir(d) {
                this.dir = d;
                axios.post(API_URL + 'scandir.php',{
                    dir: '../../'+PARTNER+'/docs/procesos/'+d,
                    username: sessionStorage.username,
                    token: sessionStorage.token
                }).then(response => {
                    if(!response.data.auth){
                        this.$emit('logout')
                    } else {
                        this.files = response.data.list
                    }
                });
            },
            goBack() {
                this.files = [];
                this.dir = '';
            },
            goTo(url) {
                window.open('https://'+PARTNER+'.tecnoclub.org/docs/procesos/'+url);
            },
        },
        created() {
            if(this.logged == 0){
                this.$router.push('/')
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../theme';
    @import '../../node_modules/bootstrap/scss/bootstrap.scss';
</style>
