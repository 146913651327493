import Vue from 'vue'
import VueRouter from 'vue-router'
import BulletinsView from '@/views/BulletinsView.vue'
import Process from '@/views/ProcessView.vue'
import Lessons from '@/views/LessonsView.vue'
import Equipment from '@/views/EquipmentView.vue'

import autologin from "@/views/AutoLogin.vue";

//IMPORTANT! Change PARTNER before build for production
// import Login from '@/views/LoginView_cga.vue'
// import Login from '@/views/LoginView_atehi'
// import Login from '@/views/LoginView_tech'
// import Login from '@/views/LoginView_aser'
// import Login from '@/views/LoginView_eaata'
// import Login from '@/views/LoginView_next'
import Login from '@/views/LoginView_create'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/boletines',
    name: 'boletines',
    component: BulletinsView
  },
  {
    path: '/boletines/:maker',
    name: 'models',
    component: BulletinsView
  },
  {

    path: '/boletines/:maker/:model',
    name: 'vehicles',
    component: BulletinsView
  },
  {
    path: '/boletines/:maker/:model/:vehicle',
    name: 'categories',
    component: BulletinsView
  },
  {
    path: '/boletines/:maker/:model/:vehicle/:category',
    name: 'viewer',
    component: BulletinsView
  },
  {
    path: '/procesos',
    name: 'process',
    component: Process
  },
  {
    path: '/formacion',
    name: 'lessons',
    component: Lessons
  },
  {
    path: '/equipamiento',
    name: 'equipment',
    component: Equipment
  },
  {
    path: '/autologin/:user/:token',
    name: 'autologin',
    component: autologin
  },
  { path:'*', redirect: '/'}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
