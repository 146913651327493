<template>
    <div>
        <div class="background"></div>
        <div class="container-fluid" style="height:100vh">

            <div class="row h-100 align-items-center justify-content-center">
                <div class="col" style="max-width:700px;">
                    <div class="row">
                        <div class="col-12 col-sm-6 bg-white h-auto">
                            <div class="row justify-content-center align-items-center h-100">
                                <div class="col-auto px-3 py-4 text-center">
                                    <hr>
                                    <img class="px-3" src="../assets/create.svg" alt="" height="100px">
                                    <hr>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 h-auto py-4" style="background-color: #222">

                            <div class="container text-white">
                                <div class="text-center">
                                    <h2>Iniciar Sesión</h2>
                                </div>
                                <hr>
                                <div class="alert alert-danger py-1" v-if="showerror">Usuario o contraseña incorrectos</div>
                                <form>
                                    <div class="form-group">
                                        <label>Usuario</label>
                                        <input type="text" class="form-control" v-model="username" placeholder="Nombre de usuario">
                                    </div>
                                    <div class="form-group">
                                        <label>Contraseña</label>
                                        <input type="password" class="form-control" v-model="password" placeholder="Contraseña">
                                    </div>
                                    <hr>
                                    <div class="text-right">
                                        <button type="submit" class="btn btn-light font-weight-bold" @click.prevent="signIn"><i class="fas fa-sign-in-alt"></i> ENTRAR</button>
                                    </div>
                                </form>

                            </div>

                        </div>
                        <div class="col-12 text-center text-white d-block d-lg-none"><small>Desarrollado por New Technologyes Group S.L. 2010</small></div>
                    </div>
                </div>
            </div>

            <div class="row d-none d-lg-block" style="bottom:30px; height:30px; position: relative;">
                <div class="col text-white" align="center"><small>Desarrollado por New Technologyes Group S.L. 2010</small></div>
            </div>

        </div>
    </div>
</template>

<script>
    import settings from "../settings";
    import axios from 'axios';
    let API_URL = settings.API_URL;
    export default {
        name: "login-view",
        data() {
            return {
                username: '',
                password: '',
                showerror: 0
            }
        },
        methods:{
            signIn() {
                axios.post(API_URL + 'checkLogin_create.php',{
                    user: this.username,
                    password: this.password
                }).then(response => {
                    if( response.data.allowed ) {
                        this.$emit('login', this.username);
                        sessionStorage.username = response.data.username
                        sessionStorage.token = response.data.token
                    }else{
                        this.showerror = 1;
                    }
                });
            }
        },
        mounted(){
            this.$emit('logout');
        }
    }
</script>

<style scoped>
    .background {
        background:url('../assets/create-bg.jpg') center center no-repeat;
        background-size:cover;
        filter: opacity(80%);
        width: 100%;
        z-index: -1;
        min-height: 100%;
        position: fixed;
    }
    img {
        max-width: 100%;
    }
</style>
