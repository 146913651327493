export default {
    API_URL: 'https://api.tecnoclub.org/infotechnic3/',
    // PARTNER: 'cga',
    // PARTNER: 'atehi',
    // PARTNER: 'tech',
    // PARTNER: 'aser',
    // PARTNER: 'eaata',
    // PARTNER: 'next',
    PARTNER: 'create',
}

/* THEME SELECTOR
*  settings.js
*  theme.scss
*  router.js
*  App.vue (logo)
* */
