<template>
    <div class="container-fluid">
        <div class="row" v-if="dir==''">
<!--          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeHOYopDCsM21Qfi6PLSXtjv5cTZ9wiRjxJrot9tmrpNnM4SA/viewform?embedded=true" width="640" height="2888" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>-->
            <div v-for="s in sections" class="col-12 col-sm-6 col-md-3 col-xl-2 mb-3" :key="s.tag">
                <div class="card h-100">
                    <img class="card-img-top" :src="'https://infotechnic.tecnoclub.org/images/' + s.tag +'.jpg'" alt="Card image cap">
                    <div class="card-body">
                        <h5 class="card-title">{{ s.title }}</h5>
                        <p class="card-text small">{{ s.text }}</p>
                        <div class="btn-group">
                          <a href="#" class="btn btn-primary" @click="getDir(s.tag)"><small>Temarios</small></a>
                          <a :href="s.test" target="_blank" class="btn btn-dark ml-1" @click="getDir(s.tag)" v-if="s.test != ''"><small>Autoevaluativos</small></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End row -->
        <div class="row" v-else>
            <div class="container">
                <button class="btn btn-dark mb-3" @click="goBack"><i class="fas fa-arrow-alt-left"></i> Atrás</button>
                <table class="table table-dark table-striped table-hover">
                    <tbody style="cursor:pointer">
                    <tr @click="goTo(dir+'/'+f+'.pdf')" v-for="f in files" :key="f"><td>{{ f }}</td><td></td></tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- End row -->
    </div>
</template>

<script>
    import axios from 'axios';
    import settings from "../settings";
    let API_URL = settings.API_URL;
    let PARTNER = settings.PARTNER;
    export default {
        name: "lessons-view",
        props: ['logged'],
        data() {
            return {
                sections: [
                    {title: 'Sistemas de Encendido', tag: 'encendido', text: 'Bujías, bobinas, cables, distribuidores y todo sobre el sistema de encendido...', test: 'https://drive.google.com/drive/folders/1fo99bz0ekEjBgaWXBM-K6e2TXyNNXwKD?usp=sharing'},
                    {title: 'Inyección Gasolina', tag: 'gasolina', text: 'Admisión y distribución variable, inyección estratificada, turboalimentados...', test: 'https://drive.google.com/drive/folders/1YqpUphmdUX41WqBAES31sWWvYqt1HUv7?usp=sharing'},
                    {title: 'Inyección Diesel', tag: 'diesel', text: 'Sistemas Common Rail, inyector-bomba, VP44, filtros de particula, aditivos...', test: 'https://drive.google.com/drive/folders/1p2X8B4Ph60Sp3RKsfs2cH4_Z6-BtLfYk?usp=sharing'},
                    {title: 'Seguridad Activa', tag: 'seguridad-activa', text: 'Anti-bloqueo de ruedas, control de tracción y de estabilidad, iluminación, suspensión activa...', test: 'https://drive.google.com/drive/folders/1pPipgVIF-_YDXrB6tYjw1v-SzMpJ_gV5?usp=sharing'},
                    {title: 'Seguridad Pasiva', tag: 'seguridad-pasiva', text: 'Airbags, pretensores, cinturones de seguridad, ocupación de asiento, sensores de impacto...', test: 'https://drive.google.com/drive/folders/15FP5hVkyq03U7YZDYWIcBpfiB4oMHdxa?usp=sharing'},
                    {title: 'Climatización y calefacción', tag: 'clima', text: 'Aire acondicionado, climatizadores regulados, compresores de carrera variable...', test: 'https://drive.google.com/drive/folders/1QsWSiSx0NVorqkKgfaqu-1t7LpxusucY?usp=sharing'},
                    {title: 'Cambios Robotizados', tag: 'cambios-robotizados', text: 'Cambios de doble embrague, cajas mecanicas robotizadas, cambios secuenciales...', test: 'https://drive.google.com/drive/folders/1rSYjUtOanjm4Rv2l3LvehdHgSynZWEM2?usp=sharing'},
                    {title: 'Vehículos Híbridos', tag: 'hibridos', text: 'Funcionamiento de un motor híbrido, sistema de alta tensión, seguridad de los operarios...', test: 'https://drive.google.com/drive/folders/1avqK__-L0IEsYPZpVLVLCXsa6Km4bqzF?usp=sharing'},
                    {title: 'Sistemas Multiplexados', tag: 'multiplexados', text: 'Comunicación LIN, VAN y CAN, centralitas, gateways, conectores, cableado...', test: 'https://drive.google.com/drive/folders/1v8rP576ansuoePEs469pQIx1DiJst4GV?usp=sharing'},
                    {title: 'Sistema StartStop', tag: 'startstop', text: 'Componentes específicos, baterías AGM, y condiciones de funcionamiento...', test: 'https://drive.google.com/drive/folders/1OPVdCT348YLwT2bG4YTn050nPZsNPID6?usp=sharing'},
                    {title: 'Sistema de Diagnostico', tag: 'diagnosis', text: 'Procedimientos de diagnostico en diferentes fabricantes y modelos y sistemas...', test: 'https://drive.google.com/drive/folders/1PAapY1dAUuODX1cYYClW4h9BZVO-Wb-6?usp=sharing'},
                    {title: 'Sistemas de Tracción', tag: 'traccion', text: 'Tracción a las cuatro ruedas, diferenciales, caja y árbol de transferencia, haldex...', test: 'https://drive.google.com/drive/folders/1E2KQKh73yX_WiHsxnU0e3iPI8oFLUc0N?usp=sharing'},
                ],
                dir: '',
                files: []
            }
        },
        methods: {
            getDir(d) {
                this.dir = d;
                axios.post(API_URL + 'scandir.php',{
                    dir: '../../'+PARTNER+'/docs/formacion/'+d,
                    username: sessionStorage.username,
                    token: sessionStorage.token
                }).then(response => {
                    if(!response.data.auth){
                      this.$emit('logout')
                    } else {
                        this.files = response.data.list
                    }
                });
            },
            goBack() {
                this.files = [];
                this.dir = '';
            },
            goTo(url) {
                window.open('https://'+PARTNER+'.tecnoclub.org/docs/formacion/'+url);
            },
        },
        created() {
            if(this.logged == 0){
                this.$router.push('/')
            }
        },
        watch: {

        }
    }
</script>

<style lang="scss" scoped>
    @import '../theme';
    @import '../../node_modules/bootstrap/scss/bootstrap.scss';
</style>
