<template>
  <div>
    <!-- Header -->
    <div class="container-fluid" v-if="logged">
      <div class="row align-items-center py-1 sticky-top top-row" style="background-color: white">
        <!-- Logo -->
        <div class="col-auto">
          <!--              <img class="logo" src="./assets/logo-cga.png" alt="" height="60px">-->
          <!--              <img class="logo" src="./assets/logo-menu-atehi.png" alt="" height="60px">-->
          <!--              <img class="logo" src="./assets/logo-tc-menu.png" alt="" height="60px">-->
          <!--              <img class="logo" src="./assets/logo-aser-menu.png" alt="" height="60px">-->
          <!--              <img class="logo my-2" src="./assets/logo-eaata.png" alt="" height="30px">-->
          <!--          <img class="logo my-2" src="./assets/isotipo-color.png" alt="" height="30px">-->
          <img class="logo" src="./assets/create.png" alt="" height="50px">
        </div>
        <!-- Menu -->
        <div class="col-auto d-none d-md-inline-flex pl-0">
          <div class="btn-group">
            <router-link v-for="m in menu" :to="'/'+m.code"
                         :class="'btn '+[currentPage.includes(m.code) ? 'btn-primary' : 'btn-dark']" :key="m.code">
              <i :class="m.icon + ' d-block d-xl-none fa-lg'"></i>
              <i :class="m.icon + ' d-none d-xl-inline-block fa-lg'"></i>
              {{ m.name }}
            </router-link>
          </div>
        </div>
        <!-- Menu button-->
        <div class="col-auto d-block d-md-none pl-0">
          <div class="btn-group">
            <button class="btn btn-primary" @click.prevent="phoneMenu = !phoneMenu"><i class="far fa-bars"></i></button>
          </div>
        </div>
        <!-- Auto Space -->
        <div class="col p-0"></div>
        <!-- User Section -->
        <div class="col-auto">
          <div class="dropdown">
            <button class="btn btn-dark dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
              <i class="fal fa-user fa-lg"></i> {{ userName }}
            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item" href="#" @click.prevent="logout">Salir</a>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-0">
      <!-- Menu for Smartphones -->
      <transition name="menu">
        <div v-if="phoneMenu" class="row mb-3">
          <div class="col-12">
            <div class="btn-group-vertical d-flex">
              <router-link
                  v-for="m in menu"
                  :to="'/'+m.code"
                  :class="'btn '+[currentPage.includes(m.code) ? 'btn-primary' : 'btn-dark']+' btn-lg text-left'"
                  :key="m.code"
                  @click.native="phoneMenu = 0">
                <i :class="m.icon+' fa-fw'"></i> {{ m.name }}
              </router-link>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- Page Section -->
    <router-view @login="login" @logout="logout" :logged="logged"/>

    <!-- DESACTIVAR PARA CGA -->
    <div v-if="logged" class="container-fluid" style="bottom:0px; height:100px; position: relative;">
      <!--      <div align="center"><img src="./assets/logo-color-horizontal.png" style="height:60px" alt=""></div>-->
      <div align="center">
        <img src="./assets/create.svg" style="height:70px" alt="">
        <img class="ml-3" src="./assets/create-h.png" style="height:70px" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import settings from "./settings";
import axios from 'axios';

let API_URL = settings.API_URL;
export default {
  name: 'App',
  data() {
    return {
      logged: 0,
      menu: [
        {code: 'boletines', name: 'Boletines', icon: 'fad fa-book-open mr-1'},
        {code: 'procesos', name: 'Procesos', icon: 'fad fa-wave-square mr-1'},
        {code: 'formacion', name: 'Formación', icon: 'fad fa-graduation-cap mr-1'},
        {code: 'equipamiento', name: 'Equipamiento', icon: 'fad fa-toolbox mr-1'}
      ],
      phoneMenu: 0,
      userName: '',
      token: '',
      counts: '',
    }
  },
  computed: {
    currentPage() {
      return this.$route.path;
    }
  },
  methods: {
    login(user) {
      this.logged = 1;
      this.userName = user;
      this.$router.push('/boletines')
    },
    logout() {
      this.logged = 0;
      this.$router.push('/')
    },
    counters() {
      axios.get(API_URL + 'counters.php').then(response => {
        this.counts = response.data
      });
    },
  },
  created() {
    this.counters();
    this.userName = sessionStorage.username;
    this.token = sessionStorage.token;
  }
}
</script>

<style lang="scss" scoped>
@import 'theme.scss';
@import '../node_modules/bootstrap/scss/bootstrap.scss';
</style>

<style>
.logo {
  max-height: 50px;
}

.top-row {
  background-color: white;
  box-shadow: 0 3px 5px #AAA;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.menu-enter-active {
  transition: all 0.5s ease;
}

.menu-leave-active {
  transition: all 0.5s ease;
}

.menu-enter, .menu-leave-to {
  max-height: 0px;
  overflow: hidden;
}

.menu-enter-to, .menu-leave {
  max-height: 500px;
  overflow: hidden;
}
</style>
